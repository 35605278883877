import React from 'react';
import { Link } from 'gatsby';
import f1 from '../images/bg/f1car.jpg'

const HomeRecentProjects = () => {

  return (
    <section  id="projects" style={{ padding: '235px 0',background: `url(${f1})`,backgroundRepeat: 'no-repeat',backgroundSize:'cover',backgroundPosition: '0px' }} >
      <style>
      {`
    .fet_pr-carousel-title {
      position: absolute;
      left: 0px !important;
    
      z-index: 11;
      top: 30%;
    }

  `}
      </style>
      <div className="fet_pr-carousel-title" style={{ width: '100%' }}>
        <div className="fet_pr-carousel-title-item" style={{ maxWidth: '1000px' }}>
          <h3 >Featured Projects</h3>
          <p >Few of our project highlights</p>
          <Link to="https://projects.pragicts.com/" target='_blank' className="btn flat-btn color-btn mar-top">View Portfolio</Link>
        </div>
      </div>
      <div className="slider-carousel-wrap fl-wrap">
      </div>
    </section>
  );
};

export default HomeRecentProjects;
